<template>
  <div class="home">
    <div class="tap_wrapper">
      <el-carousel
        v-if="list.length"
        :autoplay="autoplay"
        indicator-position="none"
        ref="carousel"
        :height="h + 'px'"
        arrow="never"
        :interval="5000"
      >
        <el-carousel-item v-for="(i, k) in list" :key="k">
          <img
            :src="i.homePictureUrl"
            width="100%"
            height="100%"
            alt=""
            srcset=""
          />
        </el-carousel-item>
      </el-carousel>
      <div class="left" @click="toLeft"></div>
      <div class="right" @click="toRight"></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";

export default {
  name: "Home",
  data() {
    return {
      h: 800,
      autoplay: true,
      list: [],
    };
  },
  created() {
    let w = window.innerWidth || document.documentElement.clientWidth;
    if (w <= 750) {
      this.h = (w / 1200) * 800;
    }
    this.getData();
  },
  methods: {
    getData() {
      axios
        .post("/PictureSystem/homePage/homePageList")
        .then(({ data }) => {
          if (data.status == "success") {
            this.list = data.data || [];
          } else {
            this.$notify.error(data.message || "获取失败");
          }
        })
        .catch((err) => {
          this.$notify.error("网络错误");
        });
    },
    toLeft() {
      this.$refs.carousel.prev();
    },
    toRight() {
      this.$refs.carousel.next();
    },
  },
};
</script>

<style lang="scss">
.home {
  padding-top: 50px;
  overflow: hidden;
  width: 100%;

  .el-carousel__indicator button {
    border: 1px solid #dcdcdc;
  }
  .tap_wrapper {
    position: relative;
  }

  .left {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    bottom: 0;
    z-index: 9;
    cursor: w-resize;
  }
  .right {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    bottom: 0;
    z-index: 9;
    cursor: e-resize;
  }
}
</style>
