import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

let lang = localStorage.getItem('lang') || 'en';
export default new Vuex.Store({
  state: {
    lang
  },
  getters: {
    lang(state){
      return state.lang || ''
    }
  },
  mutations: {
    setLang(state, data){
      state.lang = data || '';
    }
  },
  actions: {
  },
  modules: {  
  },
});
