<template>
  <div class="common_layout architecture">
    <div
      class="wrapper"
      :style="{ height: maxHeight + 'px', minHeight: '100px' }"
    >
      <div class="nodata" v-if="list.length == 0">暂无数据 ......</div>
      <div
        class="item"
        :style="{
          width: columnWidth + 'px',
          height: i.layoutH + 'px',
          left: i.left + 'px',
          top: i.top + 'px',
          background: i.background,
        }"
        v-for="i in list"
        :key="i.architectureCatalogId"
      >
        <router-link :to="'/arc-' + i.architectureCatalogId">
          <img
            v-if="i.architectureCatalogUrl"
            :src="i.architectureCatalogUrl"
            width="100%"
            alt=""
            srcset=""
          />
          <!-- <img v-else src="" alt=""> -->
        </router-link>
        <div class="info">
          <p>{{ i.name }}</p>
          <p>{{ i.officeName }}</p>
        </div>
      </div>
    </div>
    {{ loadAll }}
    <div v-show="total > pageSize" ref="load" class="loadAll">
      {{
        loadAll == "loaded"
          ? "没有更多东西了 ... ..."
          : loadAll == "loading"
          ? "正在加载 ......"
          : ""
      }}
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { clearTimeout, setTimeout } from "timers";
import { delay } from "q";
export default {
  data() {
    return {
      list: [],
      data: [],
      total: 0,
      columnHeight: [0, 0, 0],
      maxHeight: 500,
      loadAll: "",
      currentPage: 1,
      pageSize: 15,
      columns: 3, // 列数
      columnWidth: 380, // pc列宽
      columnGap: 30, //pc列间距
    };
  },
  computed: {
    ...mapGetters(["lang"]),
  },
  methods: {
    init() {
      this.initializeEvent();
      this.getData();
    },
    getData() {
      let l = this.$loading({ text: "正在加载图片..." });
      axios
        .post("/PictureSystem/architecture/getArchitectureCatalogListFront", {
          currentPage: this.currentPage,
          pageSize: this.pageSize,
        })
        .then(({ data }) => {
          this.loadAll = "";
          if (data.status == "success") {
            let d = data.data;
            if (d.totalPage <= this.currentPage) {
              this.loadAll = "loaded";
            }
            let r = this.handleData(d.list || []);
            this.list = this.list.concat(r);
            this.total = d.totalRecord;
          } else {
            this.$notify.error(data.message || "获取失败");
          }
        })
        .catch((err) => {
          this.$notify.error("网络错误");
          this.loadAll = "";
        })
        .then(() => {
          l.close();
        });
    },
    handleData(list = []) {
      let result = [],
        c = this.columnHeight,
        cw = this.columnWidth,
        cg = this.columnGap,
        color = [
          "#fdfdda",
          "#f9f9f9",
          "#e9ffe9",
          "#ffe7ff",
          "#fbecd0",
          "#ffe2e7",
          "#e6f0ff",
          "#e4e4ff",
        ];
      list.forEach((i, k) => {
        i.architectureCatalogWidth = i.architectureCatalogWidth
          ? i.architectureCatalogWidth
          : cw;
        i.architectureCatalogHeight = i.architectureCatalogHeight
          ? i.architectureCatalogHeight
          : 250;
        i.layoutH =
          Math.floor(
            (cw / i.architectureCatalogWidth) * i.architectureCatalogHeight
          ) +
          48 +
          10;
        let mi = this.getMinHeightIndex();
        i.left = cw * mi + cg * mi;
        i.top = c[mi] + cg;
        i.background = color[k % 8];
        c[mi] = i.top + i.layoutH;
        i.name =
          this.lang == "cn"
            ? i.architectureCatalogName
            : i.architectureCatalogEnname;
        i.officeName =
          this.lang == "cn"
            ? i.architectureCatalogOfficeName
            : i.architectureCatalogOfficeEnname;
        i.architectureCatalogUrl =
          i.architectureCatalogUrl +
          "?x-oss-process=image/resize,w_760/quality,q_50";
        result.push(i);
      });
      this.maxHeight = Math.max.apply(null, c);
      return result;
    },
    initializeEvent() {
      this.doScrollCalc = this._debounce(this.loadMore, 300);
      document.addEventListener("scroll", this.doScrollCalc, false);
    },
    _debounce(fn, time) {
      var timer;
      return function () {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
          timer = undefined;
          fn.apply(this, arguments);
        }, time || 0);
      };
    },
    loadMore() {
      console.log("times", this.loadAll);
      if (this.loadAll == "loading" || this.loadAll == "loaded") return;
      let dom = this.$refs.load,
        rect = dom.getBoundingClientRect(),
        viewH =
          window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight;
      if (rect.top <= viewH + 100) {
        this.loadAll = "loading";
        this.currentPage += 1;
        this.getData();
      }
    },
    getMinHeightIndex() {
      let c = this.columnHeight;
      let mv = Math.min.apply(null, c);
      return c.findIndex((i) => i == mv);
    },
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.doScrollCalc, false);
  },
  created() {
    let w =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (w <= 750) {
      this.pageSize = 6;
      this.columns = 2;
      this.columnHeight = [0, 0];
      this.columnGap = 20;
      this.columnWidth = Math.floor(
        (w - (this.columns - 1) * this.columnGap - 20 * 2) / this.columns
      ); // 移动端2左右各留20px边距
    }
    this.init();
  },
  watch: {
    lang(n, o) {
      this.list.forEach((i) => {
        i.name =
          this.lang == "cn"
            ? i.architectureCatalogName
            : i.architectureCatalogEnname;
        i.officeName =
          this.lang == "cn"
            ? i.architectureCatalogOfficeName
            : i.architectureCatalogOfficeEnname;
      });
      this.list = [].concat(this.list);
    },
  },
};
</script>

<style lang="scss">
.architecture {
  padding-top: 18px;
}
</style>
