<template>
  <div id="app">
    <p class="lang">
      <span @click="setLang('en')" :class="{ active: lang == 'en' }">EN</span> /
      <span @click="setLang('cn')" :class="{ active: lang == 'cn', last: true }"
        >中文</span
      >
    </p>
    <div class="container">
      <div class="logo">
        <div><img src="../../assets/logo.jpg" alt="logo" /></div>
        <div><img src="../../assets/logoRight.jpg" alt="logo" /></div>
      </div>
      <ul class="nav">
        <li
          :class="{ first: index == 0, last: index == 5 }"
          v-for="(i, key, index) in nav"
          :key="key"
        >
          <router-link
            :class="{ active: $route.meta.cat == key }"
            :to="key"
            tag="span"
            >{{ i }}</router-link
          >
        </li>
      </ul>
      <router-view />

      <footer>
        <p class="footer">
          <a
            style="color: #999"
            href="https://beian.miit.gov.cn/"
            target="_blank"
            >京ICP备18010175号-2</a
          ><br />Copyright ©2019-2021 夏至 版权所有
        </p>
      </footer>

      <div @click="toTop" v-show="showTop" class="top">
        <span class="el-icon-top"></span>
      </div>
    </div>
  </div>
</template>

<script>
import data from "../../data.js";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      nav: {},
      showTop: false,
    };
  },
  computed: {
    ...mapGetters(["lang"]),
  },
  created() {
    let lang = this.lang;
    this.syncLang(lang);
    // let w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    // window.addEventListener('resize', function(){
    //   let rw = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    //   console.log(w,rw)
    //   if(w > 750 && rw < 750){
    //     location.reload();
    //   }else if(w<750 && rw > 750){
    //     location.reload();
    //   }
    // })
  },
  mounted() {
    let vm = this;
    document.addEventListener(
      "scroll",
      function () {
        let st = document.documentElement.scrollTop || document.body.scrollTop,
          wh = window.innerHeight || document.documentElement.clientHeight;
        if (st >= wh / 4) {
          vm.showTop = true;
        } else {
          vm.showTop = false;
        }
      },
      false
    );
  },
  methods: {
    setLang(lang) {
      if (lang == this.lang) return;
      localStorage.setItem("lang", lang);
      this.$store.commit("setLang", lang);
    },
    syncLang(lang) {
      if (lang == "en") {
        this.nav = data.en;
      } else {
        this.nav = data.cn;
      }
    },
    toTop() {
      document.documentElement.scrollTop = document.body.scrollTop = 0;
    },
  },
  watch: {
    lang(n, o) {
      this.syncLang(n);
    },
  },
};
</script>

<style lang="scss">
body,
html {
  min-width: 1200px;
}
@media screen and (max-width: 768px) {
  .logo {
    div:nth-child(1) {
      margin: 0 auto;
      img {
        max-width: 175px !important;
      }
    }
  }

  .logo {
    div:nth-child(2) {
      display: none;
    }
  }
}

.container {
  width: 1200px;
  margin: 0 auto;
}

.logo {
  display: flex;
  justify-content: space-between;
  padding: 60px 0 50px;
  margin-left: -8px;
  //text-align: center;

  div:nth-child(1) {
    img {
      max-width: 375px;
    }
  }
  div:nth-child(2) {
    img {
      // max-height: 100%;
      max-height: 150px;
    }
  }
}

.nav {
  font-size: 24px;
  color: #999;
  li {
    display: inline-block;
    width: 17%;
    text-align: center;

    span {
      position: relative;
      cursor: pointer;

      &.active {
        color: #000;
      }
    }
  }
  .last {
    text-align: right;
    width: 18%;
  }
  .first {
    width: 13%;
    text-align: left;
  }
}

.lang {
  text-align: right;
  padding: 10px 20px;
  color: #999;
  width: 1200px;
  margin: 0 auto;

  span {
    cursor: pointer;
  }

  .active {
    color: #2c3e50;
    border-bottom: 1px solid #2c3e50;
  }
}

.footer {
  padding: 150px 0 50px;
  color: #999;
  font-size: 14px;
  text-align: center;
  line-height: 1.5;
}

.top {
  position: fixed;
  right: 20px;
  bottom: 50px;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  line-height: 40px;
  text-align: center;
  font-size: 24px;
  color: #999;
  border: 1px solid #dcdcdc;
  font-weight: bold;
  cursor: pointer;
  z-index: 9999;
  background: #fff;
}
</style>
