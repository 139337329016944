import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Arc from "../views/architecture.vue";
import Arcs from "../views/arc.vue";
import About from "../views/about.vue";

import Interior from '../views/interior.vue';
import Interiors from '../views/interiors.vue';

import Travel from "../views/travel.vue";
import Travels from "../views/travels.vue";

import Video from "../views/video.vue";

import Detail from "../views/detail.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: '/home'
  },
  {
    path: "/home",
    component: Home,
    meta: {
      cat: 'home'
    }
  },
  {
    path: "/architecture",
    component: Arc,
    meta: {
      cat: 'architecture'
    }
  },
  {
    path: "/arc-:id",
    component: Arcs,
    meta: {
      cat: 'architecture'
    }
  },
  {
    path: "/interior",
    component: Interior,
    meta: {
      cat: 'interior'
    }
  },
  {
    path: "/interior-:id",
    component: Interiors,
    meta: {
      cat: 'interior'
    }
  },
  {
    path: "/travel",
    component: Travel,
    meta: {
      cat: 'travel'
    }
  },
  {
    path: "/travel-:id",
    component: Travels,
    meta: {
      cat: 'travel'
    }
  },
  {
    path: "/video",
    component: Video,
    meta: {
      cat: 'video'
    }
  },
  {
    path: "/about",
    component: About,
    meta: {
      cat: 'about'
    }
  },
  {
    path: "/list-:type",
    component: Detail
  },
  // {
  //   path: "/architecture",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = new VueRouter({
  routes,
});

export default router;
