<template>
  <div class="detail">
    <!-- <div :class="['pic', type == 'video' && 'video']" :style="{height: h+'px'}"> -->
    <div :class="['pic', type == 'video' && 'video']">
      <el-carousel
        :initial-index="index"
        @change="change"
        v-if="list.length && (os != 'phone' || type == 'video')"
        :autoplay="autoplay"
        ref="carousel"
        :height="h + 'px'"
        indicator-position="none"
        arrow="never"
        :interval="5000"
      >
        <el-carousel-item v-for="(i, k) in list" :key="k">
          <div class="inner">
            <img
              :class="i.direction"
              :style="{ maxHeight: h + 'px' }"
              v-if="type != 'video'"
              :src="i.PictureUrl"
              alt=""
              srcset=""
            />
            <!-- <video :style="{maxHeight: h+'px', height: i.PictureHeight + 'px', background: '#fff', width: i.PictureWidth + 'px', maxWidth: '100%'}" :poster="i.PictureUrl" controls v-else :src="i.Url" ></video> -->
            <video
              v-else-if="k == index"
              preload
              :poster="h < 600 && i.PictureUrl"
              :style="{ height: '450px', width: '900px' }"
              controls
              :src="i.Url"
            ></video>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div v-else-if="os == 'phone' && list.length" class="detail-phone">
        <img :src="list[index].PictureUrl" alt="" />
      </div>
      <template v-if="type != 'video'">
        <div @click="toLeft" class="left"></div>
        <div @click="toRight" class="right"></div>
      </template>
      <div @click="close" class="close">
        <span class="el-icon-close"></span>
      </div>
    </div>
    <div class="info">
      <p>{{ name }}</p>
      <p>{{ officeName }}</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["lang"]),
    name() {
      if (this.lang == "cn") {
        return this.cur.PictureName;
      } else {
        return this.cur.PictureEnname;
      }
    },
    officeName() {
      if (this.lang == "cn") {
        return this.cur.OfficeName;
      } else {
        return this.cur.OfficeEnname;
      }
    },
  },
  data() {
    return {
      list: [],
      url: "",
      cid: "",
      index: 0,
      cur: {},
      type: "",
      autoplay: false,
      h: 600,
      rate: 1,
      os: "pc",
    };
  },
  created() {
    if (/(iphone|ios|pad|android|phone)/i.test(navigator.userAgent)) {
      this.h = 400;
      this.os = "phone";
    }
    this.parseUrl();
    this.getList();
  },

  watch: {
    $route() {
      this.parseUrl();
      this.getList();
      console.log(this.index);
    },
  },
  methods: {
    parseUrl() {
      const urlO = {
        architecture: "/PictureSystem/architecture/getArchitectureList",
        indoor: "/PictureSystem/indoor/getIndoorList",
        travel: "/PictureSystem/travel/getTravelList",
        video: "/PictureSystem/video/getVideoList",
      };
      let params = this.$route.params.type.split("|");
      this.type = params[0];
      this.url = urlO[params[0]];
      this.cid = params[1];
      this.index = params[2] * 1;
    },
    change(cur, oldIndex) {
      this.index = cur;
      this.cur = Object.assign({}, this.list[cur]);
    },
    toLeft() {
      if (this.os != "phone") {
        this.$refs.carousel.prev();
      } else {
        let index = this.index - 1;
        if (index < 0) {
          index = this.total - 1;
        }
        this.$router.push("/list-" + this.type + "|" + this.cid + "|" + index);
      }
    },
    toRight() {
      if (this.os != "phone") {
        this.$refs.carousel.next();
      } else {
        let index = this.index + 1;
        if (index > this.total - 1) {
          index = 0;
        }
        this.$router.push("/list-" + this.type + "|" + this.cid + "|" + index);
      }
    },
    getList() {
      let l = this.$loading({ text: "正在加载图片..." });
      axios
        .post(this.url, {
          currentPage: this.currentPage,
          pageSize: this.pageSize,
          [this.type + "CatalogId"]: this.cid * 1,
        })
        .then(({ data }) => {
          if (data.status == "success") {
            let d = data.data;
            let r = this.handleData(d || []);
            this.list = r;
            this.cur = r[this.index];
            this.total = this.list.length;
          } else {
            this.$notify.error(data.message || "获取失败");
          }
        })
        .catch((err) => {
          console.dir(err);
          this.$notify.error("网络错误");
        })
        .then(() => {
          l.close();
        });
    },
    handleData(data) {
      let r = data.map((i) => {
        let o = {};
        for (let n in i) {
          let k = n.replace(this.type, "");
          o[k] = i[n];
        }
        if (i.PictureWidth / i.PictureHieght > this.rate) {
          o.direction = "horizental";
        } else {
          o.direction = "vertical";
        }
        return o;
      });
      return r;
    },
    close() {
      let p = {
        architecture: "arc-" + this.cid,
        indoor: "interior-" + this.cid,
        travel: "travel-" + this.cid,
        video: "video",
      };
      this.$router.push(p[this.type]);
    },
  },
};
</script>

<style lang="scss">
.detail {
  .pic {
    position: relative;
    text-align: center;
    max-width: 100%;
    height: 600px;
    margin-top: 80px;

    .inner {
      position: relative;
      max-height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    img,
    video {
      max-width: 100%;
    }
  }
  .video {
    .left {
      width: 10%;
    }

    .left:hover,
    .right:hover {
      background: #000;
      opacity: 0.1;
    }

    .right {
      width: 10%;
    }
  }

  // video::-webkit-media-controls {
  //     background: #fff;
  // }

  .close {
    position: absolute;
    right: 0;
    top: -40px;
    font-size: 40px;
    z-index: 999;
    color: #666;
    cursor: pointer;
  }

  .detail-phone {
    text-align: center;
    max-height: 320px;
    padding-top: 20px;

    img {
      max-height: 300px !important;
      max-width: 100%;
    }
  }

  .left {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    bottom: 0;
    z-index: 9;
    cursor: w-resize;
  }
  .right {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    bottom: 0;
    z-index: 9;
    cursor: e-resize;
  }

  .info {
    text-align: right;
    margin-top: 20px;
    font-size: 14px;
    line-height: 1.5;
  }
}
</style>
