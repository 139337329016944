<template>
    <div class="common_layout video">
        <div class="wrapper"  :style="{height: maxHeight + 'px', minHeight: '100px'}">
            <div class="nodata" v-if="list.length == 0">暂无数据 ......</div>
            <div @click="show(key)" class="item" :style="{width: columnWidth+'px', height: i.layoutH + 'px', left: i.left + 'px', top: i.top + 'px', background: i.background}" v-for="(i, key) in list" :key="key">
                <img :src="i.videoPictureUrl" width="100%" alt="" srcset="">
                <div class="info">
                    <p>{{i.name}}</p>
                    <p>{{i.officeName}}</p>
                </div>
            </div>
        </div>
        <!-- <div v-if="loadAll != 'loaded'" ref="load" class="loadAll">{{loadAll == 'loaded' ? '没有更多东西了 ... ...' : loadAll == 'loading' ? '正在加载 ......' : ''}}</div> -->
    </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
export default {
    data(){
        return {
            list: [],
            data: [],
            curIndex: 0,
            total: 0,
            columnHeight: [0,0,0],
            maxHeight: 500,
            loadAll: '',
            columns: 3, // 列数
            pageSize: 999,
            columnWidth: 380, // pc列宽
            columnGap: 30 //pc列间距
        }
    },
    computed: {
        ...mapGetters(['lang'])
    },
    methods: {
        show(k){
            let i = this.list[k];
            this.$router.push('/list-video|'+this.$route.params.id+'|'+k)
        },
        init(){
            //this.initializeEvent();
            this.getData();
        },
        getData(){
            let l = this.$loading({text: '正在加载图片...'});
            axios.post('/PictureSystem/video/getVideoList', {
                currentPage: this.currentPage,
                pageSize: this.pageSize
            }).then(({data})=>{
                this.loadAll = '';
                if(data.status == 'success'){
                    let d = data.data;
                    if(d.totalPage <= this.currentPage){
                        this.loadAll = 'loaded';
                    }
                    let r = this.handleData(d || []);
                    this.list = this.list.concat(r);
                    this.total = this.list.length;
                }else{
                    this.$notify.error(data.message || '获取失败')
                }
            }).catch(err=>{
                this.$notify.error('网络错误')
            }).then(()=>{
                    l.close();
            })
        },
        handleData(list = []){
            let result = [], 
                c = this.columnHeight,
                cw = this.columnWidth,
                cg = this.columnGap,
                color = ['yellow', '#ececec', '#bfe6bf', '#f1a8f1', 'orange', 'pink', '#ff9393', '#a3a3d4'];
            list.forEach((i,k) => {
                i.layoutH = Math.floor(cw/i.videoPictureWidth * i.videoPictureHeight) + 48 + 10;
                let mi = this.getMinHeightIndex();
                i.left = cw * mi + cg * mi;
                i.top = c[mi] + cg;
                //i.background = color[k%8];
                c[mi] = i.top + i.layoutH;
                i.name = this.lang == 'cn' ? i.videoPictureName : i.videoPictureEnname;
                i.officeName = this.lang == 'cn' ? i.videoOfficeName : i.videoOfficeEnname;
                result.push(i);
            });
            this.maxHeight = Math.max.apply(null, c);
            return result;
        },
        initializeEvent(){
            this.doScrollCalc = this._debounce(this.loadMore, 200);
            document.addEventListener('scroll', this.doScrollCalc, false)
        },
        _debounce(fn, time){
            var timer;
            return function(){
                if(timer) clearTimeout(timer);
                timer = setTimeout(()=>{
                    timer = undefined;
                    fn.apply(this, arguments);
                }, time || 0)
            }
        },
        loadMore(){
            if(this.loadAll == 'loading' || this.loadAll == 'loaded') return;
            let dom = this.$refs.load,
                rect = dom.getBoundingClientRect(),
                viewH = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
                if(rect.top <= viewH + 200){
                    this.loadAll = 'loading';
                }
        },
        getMinHeightIndex(){
            let c = this.columnHeight;
            let mv = Math.min.apply(null, c);
            return c.findIndex(i=> i==mv);
        }
    },
    beforeDestroy(){
        document.removeEventListener('scroll', this.doScrollCalc, false);
    },
    created(){
        let w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if(w <= 750){
            this.columns = 2;
            this.columnHeight = [0, 0];
            this.columnGap = 20;
            this.columnWidth = Math.floor((w - (this.columns - 1) * this.columnGap - 20 * 2) / this.columns);  // 移动端2左右各留20px边距
        }
        this.init()
    },
    watch: {
        lang(n, o){
           this.list.forEach(i=>{
                i.name = this.lang == 'cn' ? i.videoPictureName : i.videoPictureEnname;
                i.officeName = this.lang == 'cn' ? i.videoOfficeName : i.videoOfficeEnname;
            });
             this.list = [].concat(this.list);
        }
    }
}
</script>

<style lang="scss">
    .video {
        .op_wrap {
            z-index: 98 !important;
        }

        video {
            outline: none;
        }
    }
</style>
