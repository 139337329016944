<template>
  <div class="about">
    <div v-if="lang == 'en'">
      <p>XIAZHI PICTURES</p>
      <p>
        XIAZHI PICTURES, a photography and film studio founded by professional
        photographer Xia Zhi with an experienced team. The studio is dedicated
        to producing high-quality videos and images on architecture, design,
        art, humanistic documentary and brands, with both aesthetics and
        profundity, to inspire through visual storytelling, and to establish
        strong connection between the content and emotions.
      </p>
      <!-- <p>TEAM</p> -->
      <p>XIA ZHI</p>
      <p>
        Xia Zhi is a professional photographer who strives to capture the
        texture and mood of architecture and spaces, and to deliver the visual
        significance of modern architecture. Working as an architectural
        photographer since 2010, Xia Zhi has accumulated rich experience in
        documenting architecture, space, art and design, and has gradually
        formed his own unique style and aesthetics. Besides photos, Xia Zhi is
        also a veteran of storytelling with videos, of connecting architects
        with the audience to inspire more resonance and imaginations, and to
        manifest the power of the architecture and the architect’s intentions
        behind. Xia Zhi has worked with a number of world-known architects,
        including but not limited to (in alphabetical order) Coop Himmelblau,
        Heatherwick Studio, MAD, MVRDV, Neri&Hu, Open Archietcture, Vector
        Architects, among many others. His works have been widely published on
        Archdaily, Architectural Record (US), Architecture Review (UK),
        Designboom, Dezeen, Domus (IT), FRAME (NL), INTERNI (IT), Wallpaper*
        (UK), among many other prestigious architecture and design media.
        <br />
        <br />
        Xia Zhi was also the winner of Best Architecture Video in Dezeen Awards
        2021.
      </p>
      <!-- <p>ZHAOQING LI</p>
      <p>
        Zhaoqing Li is a senior film and television producer, co-director of
        CCTV's program The Growing of the Great Brand. Li is also a television
        commercial director, photographer, and executive director of reality
        show programs. With years of experiences in multi-disciplinary film and
        television productions, Li acquires special perspectives and skills of
        using sophisticated images to give each film a strong personal and
        unique style. Li has been worked for a wide range of brands, including
        but not limited to Yum China, Liby, Yili, JD.com, KFC, Bank of China,
        and Minsheng Bank.
      </p> -->
    </div>
    <div v-else>
      <p>夏制映画</p>
      <p>
        夏制映画XIAZHI
        PICTURES由职业摄影人夏至创立，是一家专业的影像制作工作室。
        拥有资深经验的摄制团队，专注创作兼具审美和深度的高品质影像内容，涵盖建筑空间、艺术设计、人文纪实和商业品牌。
        致力于通过视觉叙事打造触动人心的作品，建立内容与情感的深度连接。
      </p>
      <!-- <p>核心成员</p> -->
      <p>夏至</p>
      <p>
        职业摄影人，致力于通过影像捕捉建筑与空间的质感和情绪，传达当代建筑的视觉意义与内涵。
        自2010年从事摄影至今，在建筑、空间、艺术与设计等领域进行了丰富的实践，不断摸索出自己独特的拍摄风格和审美。照片之外，夏至也擅长运用视频描述建筑与空间的故事，在建筑师和观者之间建立联系，发生共鸣，从而激发更多层次的想象，最大程度上用视觉表达出设计者的初衷和建筑作品的生命。
        夏至先后与诸多国内外知名建筑师和事务所合作，包括（但不限于，按英文字母顺序排列）蓝天组、Heatherwick事务所、MAD、MVRDV、如恩设计研究室、Open建筑事务所、直向建筑等等。作品曾刊登于（按英文字母顺序排列）Archdaily、Architectural
        Record (美国版)、Architecture Review (英国版)、Designboom、Dezeen、Domus
        (意大利版)、FRAME (荷兰版)、 Wallpaper* (英国版)、INTERNI (意大利版)
        等重要国际建筑与设计媒体。
        <br /><br />
        夏至于2021年获Dezeen年度最佳建筑视频大奖。
      </p>
      <!-- <p>李昭庆</p>
      <p>
        资深影视制作人，央视《大国品牌》合作导演。曾担任纪录片导演、TVC广告导演、摄影师、真人秀栏目执行导演，多年从事商业广告片、人文纪录片等多类型跨领域的影视制作项目。善用唯美细腻的画面质感，与导演思维的独特视角，每一部作品都将个人风格融入其中。
        合作品牌：百勝中国、 立白、伊利、京东、肯德基、中国银行、民生银行等。
      </p> -->
    </div>
    <p align="right">
      {{ lang == "en" ? "Mail" : "邮箱" }}:xiazhiimage@163.com
    </p>
    <p align="right">
      {{ lang == "en" ? "Wechat" : "微信" }}:<img
        src="../../../assets/images/wx.jpeg"
        width="155"
        hegiht="155"
        align="top"
      />
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      cn: "",
      en: "",
    };
  },
  computed: {
    ...mapGetters(["lang"]),
  },
};
</script>

<style lang="scss">
.about {
  margin-top: 50px;
  font-size: 18px;

  p {
    line-height: 1.8;
    color: #666;
    margin-top: 20px;
  }
}
</style>
