import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import './global.scss';

import "/src/assets/reset.css";
import "element-ui/lib/theme-chalk/index.css";
import Element from 'element-ui';
Vue.use(Element);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");


document.oncontextmenu = document.body.oncontextmenu = function(){
  return false;
}