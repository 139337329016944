export default {
  en: {
    home: "Home",
    architecture: "Architecture",
    interior: "Interior",
    video: "Film",
    travel: "Travel",
    about: "About/Contact",
  },
  cn: {
    home: "首页",
    architecture: "建筑",
    interior: "空间",
    video: "短片",
    travel: "旅行",
    about: "关于 / 联络",
  },
};
